/**
 * Format a number as currency
 * By default, it will format as USD with en-US locale.
 * This can be overridden by passing in the currency and locale.
 */
export default function formatCurrency(
  value: number,
  currency = 'USD',
  locale = 'en-US'
): string {
  return Intl.NumberFormat(locale, {
    currency: currency,
    style: 'currency',
  }).format(value);
}

/**
 * Format a number as currency.
 * If the number is a whole number, it will not include decimals.
 * By default, it will format as USD with en-US locale.
 * This can be overridden by passing in the currency and locale.
 */
export function formatCurrencyNoDecimals(
  value: number,
  currency = 'USD',
  locale = 'en-US'
): string {
  const maximumFractionDigits = Number.isInteger(value) ? 0 : 2;

  return Intl.NumberFormat(locale, {
    currency: currency,
    style: 'currency',
    maximumFractionDigits,
  }).format(value);
}
