import {ProductState, VariantState} from '@/product/types';
import {createShopifyImageUrl} from '@/utilities/create-shopify-image-url';
import {preloadImage} from '@/utilities/preload-image';

const cachedProducts = new Map<
  string,
  {product: ProductState; variant: VariantState}
>();

/**
 * Caches the product and its variants in the optimistic cache.
 * Also preloads the images for each variant.
 * This allows us to show the product details immediately after adding to cart,
 * without waiting for the server response.
 *
 * @param product - The product state to be cached.
 */
export function optimisticCacheProduct(product: ProductState) {
  for (const variant of product.variants) {
    cachedProducts.set(variant.id.toString(), {
      product,
      variant,
    });
    preloadItemImage(variant);
  }
}

/**
 * Retrieves the cached product for the given variant ID.
 * @param variantId - The ID of the variant.
 * @returns The cached product, or undefined if not found.
 */
export function getCachedProduct(variantId: string) {
  return cachedProducts.get(variantId);
}

/**
 * Preloads the image for a given variant.
 * @param variant - The variant state containing the image URL.
 */
function preloadItemImage(variant: VariantState) {
  const imageUrl = variant.image?.url;
  if (!imageUrl) return;

  preloadImage(createShopifyImageUrl(imageUrl, 160));
}
